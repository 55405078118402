<template>
  <v-menu offset-y v-if="menuItems.length > 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn absolute dark fab top right color="success" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in menuItems" :key="index" @click="item.action">
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-btn absolute dark fab top right color="success" @click="defaultAction" v-else>
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'PlusMenu',
  props: {
    icon:{
      type: String,
      default: 'mdi-plus'
    },
    menuItems: {
      type: Array,
      required: true,
      default: () => [],
      validator: function (value) {
        return value.every(item => 'title' in item && 'icon' in item && 'action' in item);
      }
    },
    defaultAction: {
      type: Function,
      default: () => {},
    }
  }
};
</script>